import React, { useState } from 'react'
import { Button, Col, Container, Row, Form, Modal } from 'react-bootstrap'
import { apiRequest } from '../../apiRquest/apiRequest';
import { ValidateInputEmail, validateInputNumeric, validateInputPhoneNumber } from "../../helpers/validates";
/*
 * Valores por defecto
 */
const initialValues = {
  razon_social: '',
  cuit: '',
  first_name: '',
  last_name: '',
  email: '',
  codigo_pais: '',
  codigo_area: '',
  telefono: '',
}
const initialValuesErrorGeneral = {
  razon_social: '',
  cuit: '',
  first_name: '',
  last_name: '',
  email: '',
  codigo_pais: '',
  codigo_area: '',
  telefono: '',
}

export const SolicitarInfo = () => {
  const [data, setData] = useState(initialValues)
  const [errorMessage, setErrorMessage] = useState(initialValuesErrorGeneral)
  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [successResponse, setSuccessResponse] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target
    setData({
      ...data,
      [name]: value
    })
  }
  const send_data = () => {
    setErrorMessage(initialValuesErrorGeneral)
    let success = true
    if (data.razon_social.length === 0) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['razon_social']: "campo requerido"
      }))
      success = false
    } else if (data.razon_social.length < 3) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['razon_social']: "campo debe contener al menos 3 caracteres"
      }))
      success = false;
    }

    if (data.cuit.length === 0) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['cuit']: "campo requerido"
      }))
      success = false
    } else if (!validateInputNumeric(data.cuit)) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['cuit']: "campo cuit debe ser numérico (0-9)"
      }))
      success = false
    }

    if (data.first_name.length === 0) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['first_name']: "campo requerido"
      }))
      success = false;
    } else if (data.first_name.length < 3) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['first_name']: "campo debe contener al menos 3 caracteres"
      }))
      success = false;
    }
    if (data.last_name.length === 0) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['last_name']: "campo requerido"
      }))
      success = false;
    } else if (data.last_name.length < 3) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['last_name']: "campo debe contener al menos 3 caracteres"
      }))
      success = false;
    }

    if (data.email.length === 0) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['email']: "campo requerido"
      }))
      success = false;
    } else if (!ValidateInputEmail(data.email)) {
        setErrorMessage(prevErrorDataGenerales => ({
            ...prevErrorDataGenerales,
            ['email']: "email inválido"
        }))
        success = false;
    }
    if (data.codigo_pais.length === 0) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['codigo_pais']: "campo requerido"
      }))
      success = false;
    } else if (!validateInputNumeric(data.codigo_pais)) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['codigo_pais']: "campo codigo país debe ser numérico (0-9)"
      }))
      success = false;
    }

    if (data.codigo_area.length === 0) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['codigo_area']: "campo requerido"
      }))
      success = false;
    } else if (!validateInputNumeric(data.codigo_area)) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['codigo_area']: "campo codigo área debe ser numérico (0-9)"
      }))
      success = false;
    }

    if (data.telefono.length === 0) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['telefono']: "campo requerido"
      }))
      success = false;
    } else if (!validateInputNumeric(data.telefono)) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['telefono']: "campo teléfono debe ser numérico (0-9)"
      }))
      success = false;
    } else if (data.telefono.length < 5) {
      setErrorMessage(prevErrorDataGenerales => ({
        ...prevErrorDataGenerales,
        ['telefono']: "campo telefono debe contener al menos 5 caracteres"
      }))
      success = false;
    }
    if (success) {
      setLoading(true)
      apiRequest.post('v1/empresas/send_contact', data)
        .then((response) => {
          setStatusMsg("Datos enviados correctamente")
          setModalShow(true)
          setSuccessResponse(true)
          setData(initialValues)
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            'event': 'formulario_exitoso'
          })
          setLoading(false)
        })
        .catch((err) => {
          console.log(err);
          setStatusMsg("Hubo un problema en el guardado de los datos, pruebe nuevamente en unos instantes");
          setModalShow(true);
          setSuccessResponse(false);
          setData(initialValues);
          setLoading(false)
        })
    }
  }
  return (
    <>
      <div className="bg-lt-grey py-5">
        <Container>
          <h2 className="text-center">Contactanos para abrir
            una cuenta de inversión para tu empresa.</h2>
          <h3 className="text-center"><span className="primary-color">Potenciá tu negocio.</span></h3>

          <Row className="suscribe-talks">
            <Col xs={12} md={6} className="">
              <input
                className={`py-1 mb-1 mt-4 bg-transparent`}
                type="text"
                value={data.razon_social}
                name="razon_social"
                placeholder="Razón social"
                onChange={handleChange}
                style={{ color: "black" }}
              />
              <span style={{ color: "red", fontSize: '80%' }}>{errorMessage.razon_social}</span>
            </Col>

            <Col xs={12} md={6} className="">
              <input
                className={`py-1 mb-1 mt-4 bg-transparent`}
                type="text"
                value={data.cuit}
                name="cuit"
                placeholder="CUIT"
                onChange={handleChange}
                style={{ color: "black" }}
              />
              <span style={{ color: "red", fontSize: '80%' }}>{errorMessage.cuit}</span>
            </Col>
            <Col xs={12} md={6} className="">
              <input
                className={`py-1 mb-1 mt-4 bg-transparent`}
                type="text"
                value={data.first_name}
                name="first_name"
                placeholder="Nombre del administrador"
                onChange={handleChange}
                style={{ color: "black" }}
              />
              <span style={{ color: "red", fontSize: '80%' }}>{errorMessage.first_name}</span>
            </Col>
            <Col xs={12} md={6} className="">
              <input
                className={`py-1 mb-1 mt-4 bg-transparent`}
                type="text"
                value={data.last_name}
                name="last_name"
                placeholder="Apellido del administrador"
                onChange={handleChange}
                style={{ color: "black" }}
              />
              <span style={{ color: "red", fontSize: '80%' }}>{errorMessage.last_name}</span>
            </Col>
            <Col xs={12} md={6} className="">
            <input
                className={`py-1 mb-1 mt-4 bg-transparent`}
                type="email"
                value={data.email}
                name="email"
                placeholder="Correo"
                onChange={handleChange}
                style={{ color: "black" }}
              />
              <span style={{ color: "red", fontSize: '80%' }}>{errorMessage.email}</span>
            </Col>
            <Col xs={12} md={6} className="">
              <Row>
                <Col xs={12} md={4}>
                  <div className="d-flex align-items-end">
                    <b style={{
                      paddingRight: '0.75rem',
                      fontSize: '2rem',
                      lineHeight: 'unset'
                    }}>+</b>
                    <input
                      className={`py-1 mb-1 mt-4 bg-transparent`}
                      type="tel"
                      value={data.codigo_pais}
                      name="codigo_pais"
                      placeholder="54"
                      onChange={handleChange}
                      style={{ color: "black" }}
                    />
                  </div>
                  <span style={{ color: "red", fontSize: '80%' }}>{errorMessage.codigo_pais}</span>
                </Col>
                <Col xs={12} md={4}>
                  <input
                    className={`py-1 mb-1 mt-4 bg-transparent`}
                    type="tel"
                    value={data.codigo_area}
                    name="codigo_area"
                    placeholder="Área"
                    onChange={handleChange}
                    style={{ color: "black" }}
                  />
                  <span style={{ color: "red", fontSize: '80%' }}>{errorMessage.codigo_area}</span>
                </Col>
                <Col xs={12} md={4}>
                  <input
                    className={`py-1 mb-1 mt-4 bg-transparent`}
                    type="tel"
                    value={data.telefono}
                    name="telefono"
                    placeholder="Teléfono"
                    onChange={handleChange}
                    style={{ color: "black" }}
                  />
                  <span style={{ color: "red", fontSize: '80%' }}>{errorMessage.telefono}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="text-center mt-5">
            {!loading ? (
              <Button onClick={send_data} variant="secondary">Solicitar información</Button>
            ) : (
              <Button disabled onClick={() => false} variant="secondary">Solicitar información</Button>
            )}

          </div>

        </Container>
      </div>
      <Modal className="formmodal" show={modalShow} onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{successResponse ? 'Muchas gracias' : 'Atención'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: statusMsg }} />
        </Modal.Body>
      </Modal>
    </>
  )
}
